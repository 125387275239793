<template>
  <ul class="menu-nav">
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ 'menu-item-active']">
      <router-link to="/home" v-slot="{ href, navigate }">
        <a :href="href" target="_blank" class="menu-link" rel="opener" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">首页</span>
        </a>
      </router-link>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ 'menu-item-active']">
      <router-link to="/dashboard" v-slot="{ href, navigate }">
        <a :href="href" class="menu-link" rel="opener" @click="navigate">
          <i class="menu-icon flaticon-dashboard"></i>
          <span class="menu-text">控制台</span>
        </a>
      </router-link>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ 'menu-item-active']"  v-if="currentUser.userType == 1">
      <router-link to="/order/dial/test" v-slot="{ href, navigate }">
        <a :href="href" class="menu-link" rel="opener" @click="navigate">
          <i class="menu-icon fab fa-amazon-pay"></i>
          <span class="menu-text">支付测试</span>
        </a>
      </router-link>
    </li>

    
    <li class="menu-section">
      <h3 class="menu-text font-weight-bolder">菜单</h3>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }" v-for="(parent, index) in userMenus"
      :key="index">
      <a href="#" class="menu-link menu-toggle">
        <i v-bind:class="['menu-icon', parent.iconClass]"></i>
        <span class="menu-text">{{ parent.authorityName }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">{{ parent.authorityName }}</span>
            </span>
          </li>

          <router-link :to="authority.page" v-slot="{ href, navigate, isActive, isExactActive }"
            v-for="authority in parent.subAuthority">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i v-bind:class="['menu-bullet', authority.iconClass]">
                  <span></span>
                </i>
                <span class="menu-text mx-2">
                  {{ authority.authorityName }}
                </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
  import {
    mapGetters
  } from "vuex";

  export default {
    name: "KTMenu",
    data() {
      return {};
    },

    methods: {
      hasActiveChildren(match) {
        return this.$route["path"].indexOf(match) !== -1;
      },
    },
    computed: {
      ...mapGetters(["userMenus"]),
      ...mapGetters(["currentUser"]),
    },
  };
</script>